import EnumService from "../../../jwt/enum.service"
 /* 
    importoPagemento = riservaSpese ---> abilitare Incarico Completato
    importoPagamento = 0 e riservaSpese = 0 ---> abilitare Incarico Annullato e Completato
    importoPagamento = 0 e riservaSpese = 150 ---> da non abilitare ne Completato ne Annullato
    importoPagamento = 50 e riservaSpese = 150 ---> da non abilitare ne Completato ne Annullato
  */
  
/**
 * gestioneEsitiIncaricoNominatoHook
 * 
 * @description
 * La funzione gestisce gli esiti possibili per l'incarico nominato.
 * I parametri che vengono passati sono:
 * - esito: l'oggetto esito contenente le informazioni dell'esito da verificare
 * - listaPagamentiSpesaPerIncarico: array contenente i pagamenti relativi all'incarico
 * - importoTotalePagamentiSpesaPerIncarico: somma dell'importo dei pagamenti relativi all'incarico
 * - rispervaSpesaIncarico: riserva spesa assegnata all'incarico
 * - criterioIncaricoNominato: booleano che indica se l'incarico nominato deve essere verificato
 * 
 * La funzione restituisce true se l'esito passato deve essere disabilitato, false altrimenti.
 * 
 * @param {Object} esito
 * @param {Array} listaPagamentiSpesaPerIncarico
 * @param {Number} importoTotalePagamentiSpesaPerIncarico
 * @param {Number} rispervaSpesaIncarico
 * @param {Boolean} criterioIncaricoNominato
 * @returns {Boolean}
 */
const gesioneEsitiIncaricoNominatoHook = (esito, listaPagamentiSpesaPerIncarico, importoTotalePagamentiSpesaPerIncarico, rispervaSpesaIncarico, criterioIncaricoNominato) => {
  if(criterioIncaricoNominato){
    if(listaPagamentiSpesaPerIncarico?.length > 0 && importoTotalePagamentiSpesaPerIncarico === rispervaSpesaIncarico) {
      return !EnumService.ESITI.INCARICO_COMPLETATO?.includes(esito.description.toLowerCase()) && !EnumService.ESITI.INCARICO_CONFERMATO?.includes(esito.description.toLowerCase()) && !EnumService.ESITI.IN_AGENDA?.includes(esito.description.toLowerCase());
    }
    if(listaPagamentiSpesaPerIncarico?.length === 0 && importoTotalePagamentiSpesaPerIncarico === 0 && rispervaSpesaIncarico === 0){
      //return !EnumService.ESITI.INCARICO_ANNULLATO?.includes(esito.description.toLowerCase()) && !EnumService.ESITI.INCARICO_CONFERMATO?.includes(esito.description.toLowerCase()) && !EnumService.ESITI.IN_AGENDA?.includes(esito.description.toLowerCase());
      return !EnumService.ESITI.INCARICO_COMPLETATO?.includes(esito.description.toLowerCase()) && !EnumService.ESITI.INCARICO_CONFERMATO?.includes(esito.description.toLowerCase()) && !EnumService.ESITI.IN_AGENDA?.includes(esito.description.toLowerCase());
    }
    return !EnumService.ESITI.INCARICO_CONFERMATO?.includes(esito.description.toLowerCase()) && !EnumService.ESITI.IN_AGENDA?.includes(esito.description.toLowerCase());
  }
  return EnumService.ESITI.IN_VALIDAZIONE?.includes(esito.description.toLowerCase());
}

export default gesioneEsitiIncaricoNominatoHook