import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../data/axiosInstance/axiosInstance'; 
import executor from   '../../../data/axiosInstance/executor';

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`;
 
const initialState = {
  emails: [],
  emailContent: [{"id": 0, "listAttachment":[]}],
  emailSearch: '',
  currentFilter: 'inbox',
};

export const EmailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {
    getEmails: (state, action) => {
      state.emails = action.payload;
    },
    SearchEmail: (state, action) => {
      state.emailSearch = action.payload;
    },
    SelectEmail: (state, action) => {
      state.emailContent = action.payload;
    },
    starEmail: (state, action) => {
      state.emails = state.emails.map((email) =>
        email.id === action.payload ? { ...email, starred: !email.starred } : email,
      );
    },
    importantEmail: (state, action) => {
      state.emails = state.emails.map((email) =>
        email.id === action.payload ? { ...email, important: !email.important } : email,
      );
    },
    checkEmail: (state, action) => {
      state.emails = state.emails.map((email) =>
        email.id === action.payload ? { ...email, checked: !email.checked } : email,
      );
    },
    setIsReadEmail: (state, action) => {
      state.emails = state.emails.map((email) =>
        email.id === action.payload ? { ...email, isRead: "1" } : email,
      );
    },
    deleteEmail: (state, action) => {
      state.emails = state.emails.map((email) =>
        email.id === action.payload ? { ...email, trash: !email.trash } : email,
      );
    },
    setVisibilityFilter: (state, action) => {
      state.currentFilter = action.payload;
    },
  },
});

export const {
  SearchEmail,
  SelectEmail,
  getEmails,
  starEmail,
  importantEmail,
  setVisibilityFilter,
  deleteEmail,
  checkEmail,
  setIsReadEmail,
} = EmailSlice.actions; 

export const fetchEmails = (taskId) => async (dispatch) => {
  dispatch(getEmails([]));
  const API_URI_CALL = `${API_URL}/api/v1/bff/email/lista/${taskId}`;
  const fetchData = async () => axiosInstance.get(API_URI_CALL);
  
  const [res, err] = await executor.executeAsync(fetchData);

  console.log('res',res);
  console.log('err',err); 

  dispatch(SelectEmail([{"id": 0, "listAttachment":[]}]));
  return dispatch(getEmails(res.data));  
};

export const fetchSingleEmail = (mailId, taskId, box) => async (dispatch) => {
  const API_URI_CALL = `${API_URL}/api/v1/bff/email/visualizza/${taskId}`;
  const fetchData = async () => axiosInstance.get(API_URI_CALL,{
    headers: {
      'mailId': mailId,
      'box' : box 
    },
  }); 
  
  const [res, err] = await executor.executeAsync(fetchData);

  console.log('res',res);
  console.log('err',err); 

  return dispatch(SelectEmail(res.data));
};

export const putIsAlreadyRead = (taskId, email) => async () => {
  const API_URI_CALL = `${API_URL}/api/v1/bff/email/updateMail/${taskId}`;
  console.log("nella put per inviare email",taskId,email, API_URI_CALL);
  const fetchData = async () => axiosInstance.put(API_URI_CALL,{
   email
  }); 
  
  const [res, err] = await executor.executeAsync(fetchData);

  console.log('res',res);
  console.log('err',err); 

  return ""
};

export default EmailSlice.reducer;
